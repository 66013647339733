import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

export default () => (
  <Layout>

		<h1>Header 1</h1>

		<h2>Header 2</h2>

		<h3>Header 3</h3>

		<h4>Header 4</h4>

		<h5>Header 5</h5>

		<p>Paragraph <strong>strong</strong> <Link>link</Link></p>

		<table>
			<thead>
				<tr>
					<th>th</th>
					<th>th</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>td</td>
					<td>td</td>
				</tr>
				<tr>
					<td>td</td>
					<td>td</td>
				</tr>
			</tbody>
		</table>

  </Layout>
);


